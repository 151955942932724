import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import USFlag from '../../assets/icons/us-flag.webp';
import ESFlag from '../../assets/icons/es-flag.webp';

const Navbar = ({ setLanguage }) => {
  const navigate = useNavigate();
  const [language, setLanguageState] = useState('es');
  const { t, i18n } = useTranslation('navbar');
  console.log(t('home')); // Should log 'Home' in English and 'Inicio' in Spanish



  const handleTestClick = () => {
    navigate('/questionnaire');
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language).then(() => {
      // After changing language, log translations to verify
      console.log("Home:", t("home"));
      console.log("Solutions:", t("solutions"));
      console.log("Contact:", t("contact"));
    });
    setLanguageState(language);
  };

  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={require('../../assets/icons/Logos/Logo-removebg.png')} className="w-24" alt="Flowbite Logo" />
        </a>

        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <a href="/" className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">{t('home')}</a>
            </li>
            <li>
              <a href="/#soluciones" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('solutions')}</a>
            </li>
            <li>
              <a href="/#contacto" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('contact')}</a>
            </li>
          </ul>
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant={language === 'en' ? 'contained' : 'outlined'}
            onClick={() => changeLanguage('en')}
            className="flex items-center"
          >
            <img src={USFlag} alt="English" className="w-6 h-6 mr-2" />
            <h6 className='hidden md:block'>English</h6>
          </Button>
          <Button
            variant={language === 'es' ? 'contained' : 'outlined'}
            onClick={() => changeLanguage('es')}
            className="flex items-center"
          >
            <img src={ESFlag} alt="Español" className="w-6 h-6 mr-2" />
            <h6 className='hidden md:block'>Español</h6>
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
